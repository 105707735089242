<template>
  <div class="content">
    <sub-title>회원정보</sub-title>
    <div class="user-info">
      <span class="tk"><i class="fa fa-phone"></i> 휴대폰</span>
      <span class="t">{{userInfo.phone}}</span>
      <span class="tk"><i class="fa fa-bank"></i> 은행명</span>
      <span class="t">{{userInfo.bank}}</span>
      <span class="tk"><i class="fa fa-id-card"></i> 예금주</span>
      <span class="t">{{userInfo.beneficiary}}</span>
      <span class="tk"><i class="fa fa-id-card"></i> 계좌번호</span>
      <span class="t">{{userInfo.acNo}}</span>
      <span class="tk"><i class="fa fa-line-chart"></i> 레벨</span>
      <span class="t">{{userInfo.rank.rankName}}</span>
      <span class="tk"><i class="fa fa-sign-in"></i> 로그인정보</span>
      <span class="t">{{userInfo.lastLoginTime|datef('yyyy-MM-DD HH:mm')}}</span>
      <span class="tk"><i class="fa fa-registered"></i> 나의 추천코드:</span>
      <span class="t">{{userInfo.referreCode.code}}</span>
      <span class="tk"><i class="fa fa-user-circle"></i> 나의 추천인</span>
      <span class="t">{{userInfo.myReferrer!=null? userInfo.myReferrer.nickname : ''}}</span>

      <!-- <span class="tk" style="width: 100%; margin: 10px 0;">
             비밀번호변경
         </span>
       <span class="tk">낡은비밀번호</span>
       <span class="t">
             <input type="password" v-model="user.oldPasswd">
         </span>
       <span class="tk">새비밀번호</span>
       <span class="t">
             <input type="password" v-model="user.passwd">
         </span>
       <button @click="changePwd()" class="btn02"
          style="width: 50%;height:32px;margin: 10px 0">변경하기</button>-->


      <table class="utable">
        <tr>
          <td colspan="2">
            포인트 내역
          </td>
        </tr>
        <tr>
          <th style="text-align: center; width: 15%;height:2.5rem">일자</th>
          <th style="text-align: center;width: 85%">내용</th>
        </tr>
        <tr v-for="(item,index) in referrerPointLogs" :key="index">
          <td style="text-align: center;height: 2.8rem"
              height="2.8rem">
            {{item.createTime|datef('MM/DD HH:mm')}}
          </td>
          <td style="padding-left: 1rem;" align="left">
            {{item.content}}
          </td>
        </tr>
      </table>

    </div>

  </div>


</template>


<script>

import {changePasswd, referrerPointLog, referrerUsers} from "@/network/userRequest";
    import {postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";

    export default {
      name: "UserInfo",
      mixins: [postionMixin],
      components: {  SubTitle },
      data() {
        return {
          referrerPointLogs: [],
          userInfo: {rank: {}, referreCode: {}, myReferrer: {}},
          user: {oldPasswd: '', passwd: '', extractPasswd: ''},
          pageNum: 1,
          pageSize: 10,
          total: 1,
          orderBy: null,
          search: {},
          sportsConst,
          position: "회원정보",
        }
      },
      methods: {
        changePwd() {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          changePasswd(this.user).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.$swal({
                title: '비밀번호 변경성공',
                type: 'success',
                showCancelButton: false,
                showConfirmButton: true
              })
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        },
        getReferrerPointLog() {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          referrerPointLog(this.pageNum, this.pageSize).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            this.userInfo = this.$store.state.userInfo
            if (res.data.success) {
              this.total = res.data.total
              this.referrerPointLogs = res.data.data
            }
          })
        },
        //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
        pageChange(page) {
          this.pageNum = page
          this.getReferrerPointLog()
        },
      },
      created() {
        this.getReferrerPointLog()
      }
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontuserinfo.css");


</style>